<template>
    <div class="container-fluid colum-height page-login">
        <!-- Bloco 1 -->
        <div class="row">
            <div class="col">
                <h1 class="text-brand-linx" title="Linx">Linx</h1>
                <h2 class="text-brand-product text-uppercase text-truncate text-primary" title="Microvix">
                    Microvix
                </h2>
                <h2 class="text-brand-product text-uppercase text-truncate text-primary" :title="nomeAplicacao">
                    {{ nomeAplicacao }}
                </h2>
            </div>
        </div>

        <!-- Bloco 2 -->
        <div class="row justify-content-center" id="body">
            <div class="col-sm-4 col-xs-12">
                <form class="form-recover-password mt-5 mb-5" v-on:submit.prevent="solicitarRecuperacaoSenha" method="post">
                    <h1 class="text-uppercase">Recuperação de Senha</h1>

                    <p>
                        Digite o seu usuário no campo abaixo para receber um e-mail e escolher uma nova senha.
                    </p>

                    <div class="alert alert-danger" v-show="mensagemErro" id="div_aviso">
                        <span id="msg">{{ mensagemErro }}</span>
                        <a href="#" class="close" @click="atualizarMensagemErro(null)" title="close">x</a>
                    </div>

                    <div class="form-group">
                        <label for="f_login">Usuário</label>
                        <input
                            class="form-control"
                            type="text"
                            id="lmxta-recuperacaosenha-input-usuario"
                            name="f_login"
                            maxlength="100"
                            placeholder="Usuário"
                            v-model="login"
                            autofocus
                            required
                            :disabled="isFetching"
                        />
                    </div>

                    <div class="row button-group">
                        <div class="col-xs-12 col-sm-6">
                            <router-link
                                class="btn btn-default btn-block"
                                title="Voltar"
                                style="line-height: 1.5 !important"
                                :to="{ name: 'login' }"
                                :disabled="isFetching"
                                id="lmxta-recuperacaosenha-btn-voltar"
                                >Voltar</router-link
                            >
                        </div>
                        <div class="col-xs-12 col-sm-6">
                            <button type="submit" id="lmxta-recuperacaosenha-btn-enviar" class="btn btn-secondary btn-block" title="Enviar" :disabled="isFetching">
                                Enviar
                                <span style="float: right" v-if="isFetching">
                                    <i class="fas fa-sync fa-spin"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                    <div id="mobile-logo" class="row mt-2" style="float: right">
                        <img src="../../assets/Images/xs-logo-mob.png" class="position-relative" alt="Logo Linx" />
                    </div>
                </form>
            </div>
        </div>
        <!-- Bloco 3 -->
        <img id="outside-logo" src="../../assets/Images/xs-logo-mob.png" class="position-absolute r-20 b-40" alt="Logo Linx" />
    </div>
</template>

<script>
import swal from '@/common/alerts';

export default {
    name: 'RecuperacaoSenha',

    data() {
        return {
            isFetching: false,
            login: null,
            mensagemErro: null
        };
    },

    created() {
        if (this.$route.params.loginDigitado) {
            this.login = this.$route.params.loginDigitado;
        }
    },

    methods: {
        atualizarMensagemErro: function(mensagem) {
            this.mensagemErro = mensagem;
        },

        solicitarRecuperacaoSenha: function() {
            if (!this.login) {
                this.atualizarMensagemErro('Informe o Usuário para continuar.');
                return false;
            }

            this.isFetching = true;
            this.$store
                .dispatch('auth/recuperarSenha', this.login)
                .then((response) => {
                    let msg = `
                        <p>Foi enviado um e-mail para ${response.Usuario.Email} para auxiliá-lo na redefinição de senha.</p>
                        <p>Caso você não possua acesso ao e-mail cadastrado ou não receba o e-mail, entre em contato com um dos surpevisores:</p>
                        <ul>
                        ${response.Supervisores.map((supervisor) => {
                            return `<li>${supervisor.Nome} - ${supervisor.Email}</li>`;
                        })}
                        </ul>`;

                    this.isFetching = false;
                    swal.exibirMensagemSucesso(
                        msg,
                        () => {
                            this.$router.push({ name: 'login' });
                        },
                        false
                    );
                })
                .catch((error) => {
                    let msg = 'Ocorreu um imprevisto ao solicitar a recuperação de senha.';

                    if (error && error.response && error.response.data && error.response.data.Message) {
                        msg = error.response.data.Message;
                    }

                    this.atualizarMensagemErro(msg);
                    this.isFetching = false;

                    return;
                });
        }
    },

    computed: {
        nomeAplicacao() {
            return process.env.VUE_APP_NOME_APLICACAO;
        }
    }
};
</script>

<style>
.colum-height {
    height: 100vh;
}

.text-brand-linx {
    color: #ffb200;
    font-size: 2.625rem;
    font-weight: 300;
    margin-bottom: 0;
}

.text-brand-product {
    color: #fff;
    font-size: 3rem;
    font-weight: 300;
}

.form-recover-password h1 {
    font-size: 1.875rem;
}

#mobile-logo {
    display: none;
}

/* Portrait */
@media only screen and (max-width: 414px) {
    #outside-logo {
        display: none;
    }

    #mobile-logo {
        display: block;
    }

    .button-group {
        display: flex;
        flex-direction: column-reverse;
    }

    .btn-default,
    .btn-secondary {
        height: auto !important;
    }

    .btn-secondary {
        margin-bottom: 1rem;
    }

    .text-brand-linx {
        font-size: 2rem;
    }

    .text-brand-product {
        font-size: 2rem;
    }
}

@media only screen and (max-width: 767px) {
    .colum-height-a {
        height: 10vh;
    }

    .colum-height-b {
        height: 90vh;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .text-brand-linx {
        font-size: 2.5rem;
    }

    .text-brand-product {
        font-size: 2rem;
    }
}

/* Landscape */
@media only screen and (orientation: landscape) and (max-width: 1023px) {
    .colum-height-a {
        height: 10%;
        margin-bottom: 35px;
    }

    .text-brand-linx {
        font-size: 1.75rem;
    }

    .text-brand-product {
        font-size: 1.75rem;
    }

    img {
        display: none;
    }

    .button-group {
        display: flex;
        flex-direction: column-reverse;
    }

    .btn-default,
    .btn-secondary {
        height: auto !important;
    }

    .btn-secondary {
        margin-bottom: 1rem;
    }
}

@media only screen and (orientation: landscape) and (min-width: 576px) and (max-width: 1023px) {
    .col-sm-6 {
        max-width: 100%;
    }

    .col-sm-4 {
        flex: 0 0 66.666667% !important;
        max-width: 66.666667% !important;
    }
}
</style>
